/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import classNames from 'classnames/bind';
import Loader from 'yoda-core-components/lib/components/Loader/Loader';
import * as styles from './LoaderComponent.css';

const cx = classNames.bind(styles);

/* To make it Work the parent component should be relative */
function LoaderComponent(props) {
    return (
        <div className={cx('loaderComponent')}>
            <Loader {...props} />
        </div>
    );
}

export default LoaderComponent;
