import React, { Component } from 'react';
import getBrowserHistory from 'yoda-core-components/lib/navigation/history/getBrowserHistory';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import forEach from 'lodash/forEach';
import omit from 'lodash/omit';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import toLower from 'lodash/toLower';
import classNames from 'classnames/bind';
import 'url-search-params-polyfill';
import Layout from 'yoda-site-components/lib/components/Layout/Layout';
import defaultWrapperRenderer from 'yoda-site-components/lib/components/IrisPage/defaultWrapperRenderer';
import renderCurrentComponent from 'yoda-site-components/lib/components/IrisPage/renderCurrentComponent';
import * as irisComponentTypes from 'yoda-site-components/lib/iris/components/common';
import parseValidation from 'yoda-account-components/lib/common/parseValidation';
import Canonincalize from 'yoda-account-components/lib/components/Canonincalize/Canonincalize';
import Button from 'yoda-core-components/lib/components/Button';
import TokenProvider from 'yoda-core-components/lib/helpers/TokenProvider/TokenProvider';
import MessageBox from 'yoda-core-components/lib/components/MessageBox/MessageBox';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import cmComponentsForRewards from 'yoda-site-components/lib/CoreMedia/components/forRewards';
import RewardsPendingComponent from '../Rewards/components/RewardsPendingComponent/RewardsPendingComponent';
import LoaderComponent from '../../../components/LoaderComponent/LoaderComponent';

import * as styles from './LaunchNLearnNew.css';

const cx = classNames.bind(styles);

class LaunchNLearnNew extends Component {
    static defaultProps = {
        newLaunchNLearnDetails: {},
        newLnLActions: {},
        getAccountDetails: {},
        userState: '',
        accountId: '',
        emailFocus: true,
        preferences: {},
        featureFlags: {},
        location: {
            pathname: '',
        },
    };

    static propTypes = {
        newLaunchNLearnDetails: PropTypes.object,
        newLnLActions: PropTypes.objectOf(PropTypes.func),
        deviceType: PropTypes.objectOf.isRequired,
        signInSliderActions: PropTypes.objectOf.isRequired,
        getAccount: PropTypes.func.isRequired,
        preferences: PropTypes.objectOf(PropTypes.object),
        messagesTexts: PropTypes.objectOf(PropTypes.object).isRequired,
        featureFlags: PropTypes.objectOf(PropTypes.object),
        getAccountDetails: PropTypes.object,
        /* eslint react/no-unused-prop-types: 0 */
        userState: PropTypes.string,
        accountId: PropTypes.string,
        emailFocus: PropTypes.bool,
        location: PropTypes.objectOf(PropTypes.object),
        isNative: PropTypes.bool.isRequired,
        getUserDetails: PropTypes.func.isRequired,
    };

    static getDerivedStateFromProps(props, state) {
        const stateToUpdate = {};
        const { userState, newLaunchNLearnDetails } = props;
        const { irisDatas, userState: userStateValue } = state;
        if (userState !== userStateValue) {
            stateToUpdate.userState = props.userState;
        }
        if (!isEqual(get(newLaunchNLearnDetails, 'irisComponents', []), irisDatas)) {
            stateToUpdate.irisDatas = get(newLaunchNLearnDetails, 'irisComponents', []);
        }
        return stateToUpdate;
    }

    constructor(props) {
        super(props);
        const { deviceType: deviceTypeProp } = props;
        const deviceType = deviceTypeProp.isDesktop ? 'desktop' : 'mobile';
        /* istanbul ignore next */
        if (!__SERVER__) {
            window.jcpDLjcp = {}; // Added this object initialization to allow ensighten team to fix the issue.
        }

        this.state = {
            error: {},
            deviceType,
            email: '',
            isValidInput: false,
            userState: get(props, 'userState', ''),
            irisDatas: [],
        };
    }

    componentDidMount() {
        const { newLnLActions, featureFlags, isNative, getUserDetails } = this.props;
        const { userState, irisDatas } = this.state;
        newLnLActions.getIrisComponentList({
            searchTerm: 'cf-rewards-lnl-content',
            skipExtractUIContent: true,
            isXCloudHeaderRequired: true,
            isChannelRequired: true,
        });
        const enableRewardsWebview = get(featureFlags, 'enableRewardsWebview', true);
        const isNativeRewardsEnabled = isNative && enableRewardsWebview;
        if (isNativeRewardsEnabled) {
            getUserDetails();
        }
        this.getAccountDetails(userState);
        newLnLActions.lnLPageLoadedAction();
        if (!isEmpty(irisDatas)) {
            this.scrollToIdComponent();
        }
    }

    componentDidUpdate(prevProps) {
        const { userState, irisDatas } = this.state;
        const { featureFlags, isNative, accountId } = this.props;
        const enableRewardsWebview = get(featureFlags, 'enableRewardsWebview', true);
        const isNativeRewardsEnabled = isNative && enableRewardsWebview;
        const {
            newLaunchNLearnDetails,
            userState: prevUserState,
            accountId: prevAccountId,
        } = prevProps;
        if (userState !== prevUserState) {
            this.getAccountDetails(userState);
            userState === '0' && this.clearRestrictedDataAfterLogout();
        }
        if (isNativeRewardsEnabled && prevAccountId && accountId !== prevAccountId) {
            this.getAccountDetails(userState);
        }
        if (!isEqual(irisDatas, get(newLaunchNLearnDetails, 'irisComponents', []))) {
            this.scrollToIdComponent();
        }
    }

    onEmailChange = (event) => {
        const error = {};
        const { preferences } = this.props;
        const email = get(event, 'target.value');
        let isValidInput = false;
        const validation = parseValidation('email', email);
        if (email) {
            const isValidEmail = validation;
            if (isValidEmail) {
                error.type = get(preferences, 'signin.errorTypeEmail', '');
                error.message = get(preferences, 'signin.emptyEmailErrorMessage', '');
            }
            isValidInput = true;
        }
        this.setState({ isValidInput, error, email });
    };

    // Fires getAccount action
    getAccountDetails = (userState) => {
        const accountId = TokenProvider.get('ACCOUNT_ID');
        const { getAccount } = this.props;
        const shouldCallGetAccount = (userState === '1' || userState === '2') && accountId;

        if (shouldCallGetAccount) {
            getAccount();
        }
    };

    getEmailSectionComponent = () => {
        const {
            messagesTexts,
            getAccountDetails,
            newLaunchNLearnDetails: {
                accountIdentity: {
                    isLoading: isAccountIdentityLoading,
                    error: accountIdentityError,
                } = {},
                authEmail: {
                    isLoading: isAuthEmailLoading,
                    message: authEmailMessage,
                    isSuccess: isAuthEmailSuccess,
                } = {},
            },
            isNative,
            featureFlags,
        } = this.props;
        const enableRewardsWebview = get(featureFlags, 'enableRewardsWebview', true);
        const isNativeRewardsEnabled = isNative && enableRewardsWebview;
        const { isValidInput, error, email, userState } = this.state;
        const isLoading = isAccountIdentityLoading || isAuthEmailLoading;
        const getAccountDetailsResponse = get(getAccountDetails, 'response', {});
        let rewardsStatus =
            userState &&
            userState !== '0' &&
            toLower(get(getAccountDetailsResponse, 'rewardsProfile.status', 'rewardsLoading'));
        const errorCheck = error.type === 'ERROR_TYPE_EMAIL';
        let sectionElement = null;
        if (rewardsStatus === 'enrolled' && isNativeRewardsEnabled) {
            rewardsStatus = 'nativeenrolled';
        }
        switch (rewardsStatus) {
            case 'nativeenrolled': {
                <></>;
                break;
            }
            case 'pending': {
                const rewardsMessagesTexts = get(messagesTexts, 'rewardsDashboard', {});
                const pendingComponentTitle = get(
                    rewardsMessagesTexts,
                    'rewardsPendingCardTitle',
                    ''
                );
                const pendingEmail = get(getAccountDetailsResponse, 'emailAddress', '');
                const pendingComponentContent = `${get(
                    rewardsMessagesTexts,
                    'rewardsPendingCardContent[0]',
                    ''
                )}${pendingEmail}${get(
                    messagesTexts,
                    'rewardsDashboard.rewardsPendingCardContent[1]',
                    ''
                )}`;
                const pendingComponentFooter = get(
                    rewardsMessagesTexts,
                    'rewardsPendingCardFooter',
                    ''
                );
                const authEmailMessageObj = !isEmpty(authEmailMessage)
                    ? {
                          type: isAuthEmailSuccess ? 'success' : 'error',
                          text: authEmailMessage,
                      }
                    : {};
                sectionElement = (
                    <RewardsPendingComponent
                        activtyMapRegion="rewards launch n learn"
                        classes={{
                            wrapper: `${cx('pendingComponent')} ${dt(['bg-white', 'rounded-lg'])}`,
                            title: cx('pendingComponentTitle'),
                            footerText: `${cx('pendingComponentFooter')} ${dt([
                                'sm:!text-gray-60',
                            ])}`,
                        }}
                        content={{
                            title: pendingComponentTitle,
                            message: pendingComponentContent,
                        }}
                        onResend={this.sendAuthEmail}
                        footerText={pendingComponentFooter}
                        message={authEmailMessageObj}
                    />
                );
                break;
            }
            case 'enrolled':
            case 'notenrolled':
            case 'rewardsloading':
            case 'loyaltyenrollmentprogress': {
                const lnlMessagesTexts = get(messagesTexts, 'launchNLearn', {});
                sectionElement = (
                    <div
                        className={`${cx('sm12', 'md6', 'lg6', 'xl6', 'lnlbannerContent')} ${dt([
                            '!m-0',
                            '!p-0',
                        ])}`}
                    >
                        <div
                            className={`${cx('manageRewards')} ${dt([
                                'w-[90%]',
                                'my-0',
                                'mx-auto',
                                'smOnly:w-full',
                            ])}`}
                        >
                            <div
                                id="rewards launch n learn"
                                data-automation-id="rewards-lnl-signedin-button"
                                className={`${cx(
                                    'sm12',
                                    'md12',
                                    'lg12',
                                    'xl12',
                                    'viewRewards',
                                    'noPadding'
                                )} ${dt(['!p-0', 'w-full', 'mb-2'])}`}
                            >
                                <Button
                                    automationId="view-rewards"
                                    className={dt(['mx-auto'])}
                                    onClick={this.viewRewardsClicked}
                                >
                                    {get(lnlMessagesTexts, 'viewRewardsButton', '')}
                                </Button>
                            </div>
                        </div>
                    </div>
                );
                break;
            }
            default: {
                const lnlMessagesTexts = get(messagesTexts, 'launchNLearn', {});
                sectionElement = (
                    <div
                        data-automation-id="rewards-lnl-login-button"
                        className={`${cx('sm12', 'md6', 'lg6', 'xl6', 'lnlbannerContent')} ${dt([
                            'mb-2',
                            'mx-0',
                        ])}`}
                    >
                        {userState !== '' && (
                            <div
                                className={`${cx('manageRewards')} ${dt([
                                    'w-[90%]',
                                    'my-0',
                                    'mx-auto',
                                    'smOnly:w-full',
                                ])}`}
                            >
                                {!isEmpty(accountIdentityError) && (
                                    <div
                                        className={`${cx('sm12', 'md12', 'lg12', 'xl12')} ${dt([
                                            '!px-0',
                                        ])}`}
                                    >
                                        <MessageBox
                                            level="section"
                                            showMessage
                                            onClose={this.hideLnlErrorBox}
                                            type="error"
                                            automationId="LnL_error"
                                            contentClassName={dt(['text-left'])}
                                            theme={dt(['mb-6'])}
                                        >
                                            <div>{get(accountIdentityError, 'title')}</div>
                                        </MessageBox>
                                    </div>
                                )}

                                <div
                                    className={`${cx(
                                        'sm8',
                                        'md8',
                                        'lg8',
                                        'xl8',
                                        { invalid: !isValidInput },
                                        'animatingLabel',
                                        'noPadding'
                                    )} ${dt(['!p-0', 'relative'])}`}
                                >
                                    <label
                                        data-automation-id="lnl_email_label"
                                        className={cx('label', { errorLabel: errorCheck })}
                                        htmlFor="email"
                                    >
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        name="email"
                                        value={email}
                                        onChange={this.onEmailChange}
                                        placeholder="Enter Email Address"
                                        minLength={1}
                                        maxLength={70}
                                        className={`${cx('input', {
                                            errorField: errorCheck,
                                        })} ${dt([
                                            'block',
                                            'w-full',
                                            'text-black',
                                            'h-12',
                                            'opacity-75',
                                            'text-normal',
                                            'py-3',
                                            'pl-5',
                                            'border',
                                            'border-solid',
                                            'border-gray-60',
                                            'outline-none',
                                            'border-r-0',
                                            'shadow-none',
                                            'mx-0',
                                            'mb-0',
                                            'rounded-l-lg',
                                            errorCheck && 'border-2',
                                            errorCheck && 'border-error-border',
                                        ])}`}
                                        data-qm-block="true"
                                    />

                                    {errorCheck && (
                                        <span
                                            className={`${cx('inlineError')} ${dt([
                                                'text-error-border',
                                                '-tracking-[.47px]',
                                                'text-small',
                                                'block',
                                                'sm:!text-left',
                                            ])}`}
                                            data-automation-id="at-accPO-InlineErrorMsg"
                                        >
                                            {error.message}
                                        </span>
                                    )}
                                </div>
                                <div
                                    id="rewards launch n learn"
                                    className={`${cx(
                                        'sm4',
                                        'md4',
                                        'lg4',
                                        'xl4',
                                        'noPadding'
                                    )} ${dt(['!p-0'])}`}
                                >
                                    <Button
                                        automationId="rewards-continue"
                                        size="Lg"
                                        buttonType="Primary"
                                        onClick={this.checkAccount}
                                        isDisabled={errorCheck || !email}
                                        className={dt(['ml-0', 'rounded-l-none', 'w-full'])}
                                    >
                                        {get(lnlMessagesTexts, 'continueButton', '')}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                );
            }
        }
        return (
            <div
                className={`${cx('lnlbanner')} ${dt([
                    'bg-white',
                    'text-center',
                    'p-0',
                    'relative',
                ])}`}
            >
                {isLoading && <LoaderComponent keepOverlay />}
                {sectionElement}
            </div>
        );
    };

    clearRestrictedDataAfterLogout = () => {
        const { newLnLActions } = this.props;
        this.setState({ email: '' });
        newLnLActions.clearLnLData();
    };

    hideLnlErrorBox = () => {
        const { newLnLActions } = this.props;
        newLnLActions.clearCheckAccount();
    };

    viewRewardsClicked = () => {
        const { deviceType, email, userState } = this.state;
        const {
            preferences: { navigateToRewardsDashboard },
            signInSliderActions,
            getAccountDetails: { response },
            newLnLActions,
        } = this.props;
        const postRedirectUrl = navigateToRewardsDashboard[deviceType];
        const rewardExactStatus = toLower(get(response, 'rewardsProfile.status', ''));
        const isNotEnrolled =
            userState === '1' &&
            (rewardExactStatus === 'notenrolled' ||
                rewardExactStatus === 'loyaltyenrollmentprogress');
        const customizeConfig = {
            lnlPage: true, // flag to remove Initial errors and prepopulate value in signInSlider
            lnlEnrollPhoneSlider: true,
            forceLogin: true,
            skipOnAuthenticationSuccess: true, // To redirect to /rewards/dashboard using customStepUp
        };
        const prepopulatedForm = {
            email,
        };
        const callback = {
            onAuthentication: newLnLActions.onAuthentication,
        };
        const configurationObj =
            userState === '2'
                ? {
                      ...customizeConfig,
                      lnlEnrollPhoneSlider: false,
                      skipOnAuthenticationSuccess: false,
                  }
                : customizeConfig;
        if (userState === '2' || isNotEnrolled) {
            newLnLActions.clearLnLData();
            signInSliderActions.customStepUp(configurationObj, callback, prepopulatedForm);
        } else {
            getBrowserHistory().push(postRedirectUrl);
        }
    };

    sendAuthEmail = () => {
        const { getAccountDetails, newLnLActions } = this.props;
        const email = get(getAccountDetails, 'response.emailAddress', '');
        const payload = {
            email,
        };
        newLnLActions.sendAuthEmail(payload);
    };

    checkAccount = () => {
        const { newLnLActions, signInSliderActions } = this.props;
        const { email } = this.state;
        this.setState({ error: {} });
        newLnLActions.checkAccount(email);
        signInSliderActions.lnlPageEmailFocus(false);
    };

    scrollToIdComponent = () => {
        const { featureFlags, isNative, preferences } = this.props;
        const enableRewardsWebview = get(featureFlags, 'enableRewardsWebview', true);
        const isNativeRewardsEnabled = isNative && enableRewardsWebview;

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const scrollId = urlParams.get('scrollId');
        const scrollIdValue = document.getElementById(scrollId);

        let utmInQueryStr = false;
        const urlQuerySkipString = get(preferences, 'urlQuerySkipString', []);
        for (let i = 0; i < urlQuerySkipString.length; i += 1) {
            if (
                urlParams.get(urlQuerySkipString[i]) !== null &&
                urlQuerySkipString[i].indexOf(urlParams.get(urlQuerySkipString[i]) !== 1)
            ) {
                utmInQueryStr = true;
                break;
            }
        }

        /* istanbul ignore if */
        if (scrollIdValue) {
            scrollIdValue.scrollIntoView({ behavior: 'smooth' });
        } else if (!isNativeRewardsEnabled && !utmInQueryStr) {
            getBrowserHistory().push(window.location.pathname);
        }
    };

    renderIrisComponents = (irisComponents) => {
        const { preferences, featureFlags, deviceType } = this.props;
        const colDom = [];
        forEach(irisComponents, (component, index) => {
            const item = defaultWrapperRenderer(
                renderCurrentComponent(
                    component,
                    index,
                    irisComponentTypes.default,
                    omit(irisComponentTypes.default, ['gridcomponent']),
                    { preferences, featureFlags, deviceType }
                )
            );
            colDom.push(item);
        });
        return colDom;
    };

    /* istanbul ignore next */
    /* eslint-disable react/jsx-props-no-spreading */
    renderCmComponents = (cmComponents) => {
        const { deviceType } = this.props;
        // eslint-disable-next-line consistent-return
        const getBottomMargin = (cmComponentData) => {
            switch (cmComponentData?.styles?.bottomMargin.toUpperCase()) {
                case 'S':
                    return '16px';
                case 'NONE':
                    return '0';
                case 'M':
                    return '40px';
                case 'L':
                    return '60px';
                default:
                    return '40px';
            }
        };
        const cmArray = cmComponents.map((el) => {
            const idValue = `${el?.id}-${el?.componentType}`;
            const Element = cmComponentsForRewards[el?.componentType.toLowerCase()]?.component;
            const bottomMarginValue = getBottomMargin(el);
            if (Element) {
                return (
                    <div id={idValue} style={{ marginBottom: bottomMarginValue }}>
                        <Element {...el} data={el} deviceType={deviceType} />
                    </div>
                );
            }

            return <></>;
        });
        return cmArray;
    };

    render() {
        const {
            newLaunchNLearnDetails: { irisComponents = [], isCoreMedia = true },
            emailFocus,
            getAccountDetails,
            location: { pathname },
            preferences: {
                rewardsPageDescription = '',
                rewardsPageTitle = 'About Rewards | JCPenney',
            },
        } = this.props;
        // eslint-disable-next-line no-prototype-builtins
        const isLoaderActive = get(getAccountDetails, 'isLoading');
        const irisImageBanner = irisComponents[0];
        const otherIrisComponentsArray = irisComponents.slice(1);
        const imageBannerIrisComponent = this.renderIrisComponents([irisImageBanner]);
        const otherIrisComponents = this.renderIrisComponents(otherIrisComponentsArray);
        const cmFirstImageSection = isCoreMedia ? (
            this.renderCmComponents([irisImageBanner])
        ) : (
            <></>
        );
        emailFocus && this.email && this.email.focus();
        const otherCmData = isCoreMedia ? irisComponents.slice(1) : [];
        return (
            <Layout>
                <Canonincalize urlLocation={pathname} />
                {isLoaderActive && (
                    <LoaderComponent keepOverlay automationId="test-automation-loader-1" />
                )}
                <Helmet title={rewardsPageTitle}>
                    <meta name="description" content={rewardsPageDescription} />
                </Helmet>
                <div className={`${cx('mainContainer')} ${dt(['rounded', 'mx-6', 'mt-6'])}`}>
                    <div
                        className={`${cx('sm12', 'md12', 'lg12', 'xl12', 'noPadding')} ${dt([
                            '!p-0',
                        ])}`}
                    >
                        {isCoreMedia ? cmFirstImageSection : imageBannerIrisComponent}
                        <div className={`${cx('lnlcold')} ${dt(['w-full', 'px-0'])}`}>
                            <section>{this.getEmailSectionComponent()}</section>
                        </div>

                        {isCoreMedia ? this.renderCmComponents(otherCmData) : otherIrisComponents}
                    </div>
                </div>
            </Layout>
        );
    }
}

export default LaunchNLearnNew;
