/* Created a duplicate of LaunchNLearnActions.js as
cleanup of code will be done as part of redesign,
we don't want to mess up existing functionality */
import {
    NEW_CHECK_ACCOUNT,
    NEW_ENROLL_REWARD_CHECK,
    NEW_ENROLL_REWARD_LINK,
    NEW_SEND_AUTH_EMAIL,
    NEW_LNL_PAGE_LOADED_SUCCESS,
    NEW_LNL_ACCOUNT_FORM_LOADED_SUCCESS,
    NEW_LNL_TRACK_TRANSITION_PAGE_EVENT,
    NEW_LNL_FORM_ERROR_TRACK_EVENT,
    NEW_LNL_FORM_SUCCESS_TRACK_EVENT,
    NEW_GET_IRIS_COMPONENT_LIST,
    CLEAR_NEW_CHECK_ACCOUNT,
    LNL_ON_AUTHENTICATION,
    CLEAR_LNL_DATA,
} from '../actionTypes/LaunchNLearnNewActionTypes';

export const checkAccount = (emailId) => ({
    type: NEW_CHECK_ACCOUNT,
    payload: { emailId },
});

export const onAuthentication = () => ({
    type: LNL_ON_AUTHENTICATION,
});

export const clearCheckAccount = () => ({
    type: CLEAR_NEW_CHECK_ACCOUNT,
});

export const enrollRewardCheck = (payload) => ({
    type: NEW_ENROLL_REWARD_CHECK,
    payload,
});

export const enrollRewardlink = (payload) => ({
    type: NEW_ENROLL_REWARD_LINK,
    payload,
});

export const sendAuthEmail = (payload) => ({
    type: NEW_SEND_AUTH_EMAIL,
    payload,
});

export const lnLPageLoadedAction = () => ({
    type: NEW_LNL_PAGE_LOADED_SUCCESS,
});

export const lnlAccountFormLoadedAction = (pageName) => ({
    type: NEW_LNL_ACCOUNT_FORM_LOADED_SUCCESS,
    payload: pageName,
});

export const lnLTrackTransitonPageAction = (pageName) => ({
    type: NEW_LNL_TRACK_TRANSITION_PAGE_EVENT,
    payload: pageName,
});

export const lnLFormErrorTrackAction = (details) => ({
    type: NEW_LNL_FORM_ERROR_TRACK_EVENT,
    payload: details,
});

export const lnLFormSuccessTrackAction = (data) => ({
    type: NEW_LNL_FORM_SUCCESS_TRACK_EVENT,
    payload: data,
});

export const getIrisComponentList = (payload) => ({
    type: NEW_GET_IRIS_COMPONENT_LIST,
    payload,
});

export const clearLnLData = () => ({
    type: CLEAR_LNL_DATA,
});
